'use client';

import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { zodResolver } from '@hookform/resolvers/zod';
import Divider from '@mui/material/Divider';
import { inputClasses } from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';

import { MeApi } from '@/adapters/api';
import AppTextField from '@/components/TextField';
import { ROLES } from '@/constants/country';
import { CIGRO_SCROLL_BAR_MIXINS } from '@/constants/mixins';
import { useGlobalMe } from '@/hooks/use-global-me';
import { useTeamsQuery } from '@/layouts/navbar/use-team';
import { userQK } from '@/query-key';
import { AppRoutes } from '@/routes';
import { to } from '@/utils/async';
import { getUserTimeZone } from '@/utils/locale-support';
import { toastError, toastSuccess } from '@/utils/notification';
import { DEFAULT_MAX_CHARS } from '@/utils/string';
import { AppButtonV2 } from '@components/Button';
import AppSelect from '@components/Select/Select.component';

import { CreateProfilePayload, CreateProfilePayloadSchema } from './api';
import InputWithCountry from './components/InputWithCountry';

const CreateProfilePage = () => {
  const router = useRouter();

  const { t: tCommon } = useTranslation('common');
  const { t: terr } = useTranslation('common', { keyPrefix: 'error' });
  const { t: tSignUp } = useTranslation('common', {
    keyPrefix: 'page-sign-up',
  });

  const [curCountry, setCurCountry] = useState(getUserTimeZone());

  const { me } = useGlobalMe();

  const queryClient = useQueryClient();

  const {
    setFocus,
    handleSubmit,
    control,
    formState: {
      isSubmitSuccessful,
      isDirty,
      isSubmitting,
      isValid,
      errors: formErrors,
    },
    setValue,
    setError,
  } = useForm<CreateProfilePayload>({
    mode: 'onChange',
    defaultValues: {},
    resolver: zodResolver(CreateProfilePayloadSchema),
  });

  useEffect(() => {
    me?.email && setValue('email', me?.email);
    me?.username && setValue('username', me?.username);
  }, [me]);

  const { data: teams, isLoading } = useTeamsQuery();

  const onSubmit = async (data: CreateProfilePayload) => {
    let phoneNumber = data.phone_number;

    if (phoneNumber) {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber, curCountry.code);
      if (!parsedPhoneNumber.isValid()) {
        setError('phone_number', {
          message: 'invalid-string-mobile',
        });
        return;
      }
      phoneNumber = parsedPhoneNumber.number;
    }

    const [, apiErr, unknownErr] = await to(() =>
      MeApi.onBrowser().updateProfile({
        company_name: data.company_name,
        role: data.role ?? undefined,
        phone_number: phoneNumber,
        username: data.username,
      }),
    );

    if (unknownErr) {
      setError('root', {
        message: 'unknown error',
      });
      toastError(terr('unknown'));
      return;
    }

    // NOTE: handle error
    if (apiErr && apiErr.errorCode === '40201') {
      setError('email', {
        message: terr(apiErr.errorCode),
      });
    }

    if (apiErr) {
      setError('root', {
        message: 'server error',
      });
      toastError(terr(apiErr.errorCode));
      return;
    }

    toastSuccess(tSignUp('setup-profile-success-message'));

    await queryClient.invalidateQueries(userQK.profile(me?.id ?? ''));

    await router.push({
      pathname: AppRoutes.INDEX,
    });
  };

  return (
    <Stack gap="24px" width={352}>
      <Stack direction="column" textAlign="center" gap="8px">
        <Typography variant="h2" color="text.0">
          {tSignUp('member-profile')}
        </Typography>

        <Typography variant="body2" color="neutralV2.2">
          {tSignUp('tell-us-about-yourself')}
        </Typography>
      </Stack>

      <Stack
        bgcolor="white"
        sx={(theme) => ({
          boxShadow: `${theme.shadows[4]}`,
        })}
        borderRadius="8px"
        border="1px solid"
        borderColor="neutralV2.5"
        padding="16px"
        gap="16px"
        //
      >
        <Stack
          direction="column"
          gap="24px"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack gap={2}>
            <AppTextField
              name="username"
              required
              control={control}
              error={formErrors['username'] != null}
              maxLength={DEFAULT_MAX_CHARS}
              helperText={
                formErrors['username']?.message
                  ? tSignUp(formErrors['username']?.message, {
                      defaultValue: '',
                    })
                  : undefined
              }
              label={tSignUp('member-name')}
              placeholder={tSignUp('name-placeholder')}
            />
            <InputWithCountry
              countryOpt={curCountry}
              onSelectCountry={(opt) => {
                setCurCountry(opt);
                setFocus('phone_number');
              }}
              name="phone_number"
              control={control}
              error={formErrors['phone_number'] != null}
              helperText={
                formErrors['phone_number']?.message
                  ? tSignUp(formErrors['phone_number']?.message, {
                      defaultValue: '',
                    })
                  : undefined
              }
              label={
                <Typography variant="subhead5" color="neutralV2.2">
                  {`${tSignUp('mobile-label')}`}
                </Typography>
              }
              placeholder={tSignUp('mobile-number-placeholder')}
            />
          </Stack>

          <Divider
            sx={{
              flex: 1,
              borderColor: 'neutralV2.6',
              height: '2px',
            }}
          />

          <Stack gap="16px">
            {(isLoading || !teams?.length) && (
              <AppTextField
                name="company_name"
                control={control}
                error={formErrors['company_name'] != null}
                helperText={
                  formErrors['company_name']?.message
                    ? tSignUp(formErrors['company_name']?.message, {
                        defaultValue: '',
                      })
                    : undefined
                }
                label={`${tSignUp('company-label')} (${tCommon(
                  'optional',
                ).toLowerCase()})`}
                placeholder={tSignUp('company-placeholder')}
                sx={{
                  [`.${inputClasses.root} input`]: {
                    padding: 1,
                  },
                }}
              />
            )}

            <Stack gap="8px">
              <Typography variant="subhead5" color="neutralV2.2">
                {`${tSignUp('role')} (${tCommon('optional').toLowerCase()})`}
              </Typography>

              <AppSelect
                placeholder={tSignUp('role-placeholder')}
                options={ROLES.map((role) => ({
                  label: tSignUp(role, { defaultValue: '' }),
                  value: role,
                }))}
                onChange={(_, value) => {
                  if (value)
                    setValue('role', value, {
                      shouldTouch: true,
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                }}
                slotProps={{
                  root: {
                    style: {
                      padding: '9px 8px',
                    },
                  },
                  popper: {
                    sx: {
                      ul: {
                        ...CIGRO_SCROLL_BAR_MIXINS.sx,
                        width: '100%',
                        maxHeight: '220px',
                        overflow: 'auto',
                        bottom: 0,
                      },
                    },
                  },
                }}
              />
            </Stack>
          </Stack>

          <AppButtonV2
            disabled={
              !isDirty || !isValid || isSubmitting || isSubmitSuccessful
            }
            type="submit"
            variant="contain"
            color="tertiary"
            sx={{ display: 'flex', justifyContent: 'center', padding: '9px' }}
            //
          >
            <Typography variant="h5" color="inherit">
              {isSubmitting ? tCommon('loading') : tSignUp('continue')}
            </Typography>
          </AppButtonV2>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CreateProfilePage;
