'use client';

import { useCallback, useState } from 'react';

import Image from 'next/image';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useForm, useWatch } from 'react-hook-form';
import invariant from 'tiny-invariant';

import { AuthApi } from '@/adapters/api';
import { AppLink } from '@/components/Link';
import { AppFormControl } from '@/components/TextField';
import { CALLBACK_URL_QUERY_KEY, EMAIL_QUERY_KEY } from '@/constants';
import AppOtpInput from '@/feature-auth/otp/OtpInput.component';
import { useCurrentSearchParams } from '@/hooks/use-controlled-search-params';
import useTimeout from '@/hooks/use-timeout';
import { authenticate } from '@/lib/actions';
import { AppRoutes } from '@/routes';
import { toastError } from '@/utils/notification';
import { isValidEmail } from '@/utils/string';
import { AppButtonV2 } from '@components/Button';
import DarkLogo from '@static/assets/dark-logo.png';

import { getSignInPayloadSchema, SignInPayload } from '../api';

const OTP_INPUT_LENGTH = 5;

const THROTTLE_MS = 10000;

export default function OtpPage() {
  const email = useCurrentSearchParams(EMAIL_QUERY_KEY);
  const callbackUrl = useCurrentSearchParams(CALLBACK_URL_QUERY_KEY);

  const { t: tCommon } = useTranslation('common');
  const { t: tErr } = useTranslation('common', {
    keyPrefix: 'error',
  });
  const { t, ready, i18n } = useTranslation('common', {
    keyPrefix: 'page-otp',
  });

  const {
    handleSubmit,
    setValue,
    setError,
    formState: {
      isSubmitting,
      errors,
      isDirty,
      isLoading: isFormLoading,
      isSubmitSuccessful,
      isValid,
    },
    control,
  } = useForm<SignInPayload>({
    resolver: zodResolver(getSignInPayloadSchema(i18n)),
    values: {
      email,
    },
  });
  const otp = useWatch({ control, name: 'otp_code' });

  const [preventResend, setPreventResend] = useState(false);

  useTimeout(
    () => {
      setPreventResend(false);
    },
    preventResend ? THROTTLE_MS : null,
  );

  const validEmail = isValidEmail(email);

  const toastInvalidEmail = useCallback(() => {
    if (!ready) return;
    toastError(
      <Stack flexDirection="row" width="100%">
        <Typography component="span" variant="body1">
          {t('invalid-email')}
        </Typography>
        &nbsp;
        <Typography
          variant="body1"
          color="primary"
          component={AppLink}
          href={AppRoutes.SIGNIN}
        >
          {t('login')}
        </Typography>
      </Stack>,
      {
        preventDuplicate: true,
        autoHideDuration: 10000,
      },
    );
  }, [t, ready]);

  const onSubmit = async (data: SignInPayload) => {
    invariant(data.otp_code, 'OTP code is required');
    let redirectTo = '/';

    if (callbackUrl) {
      redirectTo = decodeURIComponent(callbackUrl);
    }

    const errorCode = await authenticate(data, redirectTo);
    if (errorCode) {
      if (errorCode === '40201' || errorCode === '40101' || !validEmail) {
        toastInvalidEmail();
        return;
      }

      setError('otp_code', {
        message: tErr(errorCode, { defaultValue: '40000' }),
      });
    }
  };

  const { mutate: resendOtp, isLoading: isResendingOtp } = useMutation({
    mutationFn: () => AuthApi.onServer().signIn({ email }),
  });

  const isGeneralLoading = isSubmitting || isFormLoading || isResendingOtp;

  const shouldPreventSubmission =
    !isDirty || isGeneralLoading || isSubmitSuccessful;

  return (
    <Box width={352}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack gap="8px" textAlign="center" alignItems={'center'}>
          <Image src={DarkLogo} alt="Endash logo" width={24} height={24} />
          <Typography variant="h2" mt="8px">
            {t('heading')}
          </Typography>
          <Typography variant="body2" color="neutralV2.2">
            {t('please-check-your-inbox-for-verification')}
          </Typography>
        </Stack>

        <Box mt="32px" textAlign="center">
          <Stack
            bgcolor="white"
            padding="16px"
            borderRadius="8px"
            gap="24px"
            sx={{
              boxShadow: (t) => t.shadows[4],
            }}
          >
            <AppFormControl
              error={errors['otp_code'] != null}
              helperText={errors['otp_code']?.message}
              renderInput={() => (
                <AppOtpInput
                  disabled={!validEmail || isSubmitting}
                  length={OTP_INPUT_LENGTH}
                  value={otp}
                  onChange={(value) => {
                    setValue('otp_code', value, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });

                    if (value.length === OTP_INPUT_LENGTH) {
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              )}
            />

            <AppButtonV2
              type="submit"
              disabled={shouldPreventSubmission || !isValid}
              variant="contain"
              color="tertiary"
              sx={{ padding: '9px', justifyContent: 'center' }}
              //
            >
              <Typography variant="h5" color="inherit">
                {isGeneralLoading ? tCommon('loading') : t('button-label-next')}
              </Typography>
            </AppButtonV2>
          </Stack>

          <Stack gap={2} mt="32px" textAlign="center">
            <Typography variant="body2" color="text.2">
              {t('description-get-code')}&nbsp;
              <Typography
                variant="button"
                color="#2539BE"
                sx={{
                  display: 'inline',
                  textTransform: 'none',
                  cursor: isResendingOtp
                    ? 'progress'
                    : preventResend
                    ? 'not-allowed'
                    : 'pointer',
                  opacity: preventResend ? 0.5 : 1,
                }}
                onClick={!preventResend ? () => resendOtp() : undefined}
                //
              >
                {t('link-click-here')}
              </Typography>
              &nbsp;
              {t('description-to-resend')}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
