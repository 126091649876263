import React, { ElementType, forwardRef, Fragment, memo, Ref } from 'react';

import { Box, BoxProps } from '@mui/material';
import lodashEscapeRegExp from 'lodash/escapeRegExp';

interface IHighlighted {
  text: string;
  highlight?: string | null;
}

const Highlighter = forwardRef(function Highlighter<C extends ElementType>(
  {
    text,
    highlight = '',
    ...delegated
  }: IHighlighted & BoxProps<C, { components?: C }>,
  ref: Ref<HTMLElement>,
) {
  if (!highlight) {
    return (
      <Box {...delegated} ref={ref}>
        {text}
      </Box>
    );
  }

  const trimmed = highlight.trim();
  const commonHighlight = `(${lodashEscapeRegExp(trimmed)
    .split(' ')
    .join('|')})`;

  const regex = new RegExp(commonHighlight, 'gi');
  const parts = text?.split(regex);

  // NOTE: not found
  if (!parts?.length) {
    return (
      <Box {...delegated} ref={ref}>
        {text}
      </Box>
    );
  }

  return (
    <Box {...delegated} ref={ref}>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <Box key={i} component="mark" bgcolor="semantic.neutral">
              {part}
            </Box>
          ) : (
            <Fragment key={i}>{part}</Fragment>
          ),
        )}
    </Box>
  );
});

export default Highlighter;
