import React from 'react';

import { Typography } from '@mui/material';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import AppButton from '@/components/Button';
import AppIcon from '@/components/Icon';

interface IGoogleLoginBtn {
  callbackUrl?: string;
}
const GoogleLoginBtn = ({ callbackUrl }: IGoogleLoginBtn) => {
  const { t } = useTranslation('common');

  return (
    <AppButton
      onClick={() => {
        signIn('google', {
          callbackUrl,
        });
      }}
      variant="text-outlined"
      fullWidth
      sx={{
        height: 36,
        gap: 2,
        justifyContent: 'center',
      }}
    >
      <AppIcon name="ic_google" size={20} />

      <Typography variant="h5" color="text.0">
        {t('continue-with-google')}
      </Typography>
    </AppButton>
  );
};

export default GoogleLoginBtn;
