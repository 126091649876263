import { useCallback } from 'react';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';

export function useControlledSearchParams(paramName: string) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const value = useCurrentSearchParams(paramName);

  const setValue = useCallback(
    (newValue: string) => {
      const params = new URLSearchParams(
        Array.from(searchParams?.entries() ?? []),
      );

      if (newValue) {
        params.set(paramName, newValue);
      } else {
        params.delete(paramName);
      }

      const search = params.toString();
      const query = search ? `?${search}` : '';
      router.push(`${pathname}${query}`);
    },
    [pathname, router, searchParams, paramName],
  );

  return [value, setValue] as const;
}

export const useCurrentSearchParams = (paramName: string) => {
  const searchParams = useSearchParams();
  return searchParams?.get(paramName) ?? '';
};
