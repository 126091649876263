'use client';

import { useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/navigation';

import { zodResolver } from '@hookform/resolvers/zod';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';

import { AuthApi } from '@/adapters/api';
import AppTextField from '@/components/TextField';
import { CALLBACK_URL_QUERY_KEY, EMAIL_QUERY_KEY } from '@/constants';
import { useCurrentSearchParams } from '@/hooks/use-controlled-search-params';
import { ApiServerError } from '@/models/common';
import { AppRoutes } from '@/routes';
import { toastError } from '@/utils/notification';
import { getAuthUrlWithParams } from '@/utils/string';
import { AppButtonV2 } from '@components/Button';
import { AppLink } from '@components/Link';
import DarkLogo from '@static/assets/dark-logo.png';

import { getSignInPayloadSchema, SignInPayload } from './api';
import GoogleLoginBtn from './components/GoogleLoginBtn';

export default function LoginPage() {
  const { t: tErr } = useTranslation('common', {
    keyPrefix: 'error',
  });
  const { t: tCommon } = useTranslation('common');

  const { t, i18n } = useTranslation('common', {
    keyPrefix: 'page-sign-in',
    nsMode: 'fallback',
  });

  const email = useCurrentSearchParams(EMAIL_QUERY_KEY);
  const callbackUrl = useCurrentSearchParams(CALLBACK_URL_QUERY_KEY);

  const [preventRageClick, setPreventRageClick] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<SignInPayload>({
    resolver: zodResolver(getSignInPayloadSchema(i18n)),
    mode: 'onChange',
    defaultValues: {
      email: email ? decodeURIComponent(email) : '',
    },
  });

  const router = useRouter();

  const { mutate: sendOtp, isLoading: isSendingOtp } = useMutation({
    // NOTE: should use default axios instance, not custom axiosClient
    mutationFn: (data: SignInPayload) =>
      AuthApi.onServer().signIn({ email: data.email }),
    onError: (error: ApiServerError) => {
      const errMsg = tErr(error.error_code?.toString() || '40000', {
        defaultValue: '40000',
      });

      toastError(errMsg);

      setPreventRageClick(false);
    },
    onSuccess: (_, variables) => {
      const queryParams = new URLSearchParams();
      queryParams.set(EMAIL_QUERY_KEY, variables.email);

      if (callbackUrl) {
        queryParams.set(CALLBACK_URL_QUERY_KEY, callbackUrl);
      }

      router.push(`${AppRoutes.OTP}?${queryParams.toString()}`);
    },
  });

  const handleSendOtp = (data: SignInPayload) => {
    sendOtp(data);
    setPreventRageClick(true);
  };

  const disabled = isSendingOtp || isSubmitting || preventRageClick;

  return (
    <Stack width={352} direction="column" gap="32px">
      <Stack direction="column" alignItems="center" gap="8px">
        <Image src={DarkLogo} alt="Endash logo" width={24} height={24} />
        <Typography variant="h2" color="text.0" mt="8px">
          {t('log-in-to-endash')}
        </Typography>
        <Typography variant="body2" color="neutralV2.2">
          {t('welcome-message')}
        </Typography>
      </Stack>

      <Stack
        bgcolor="white"
        sx={(theme) => ({
          boxShadow: `${theme.shadows[4]}`,
        })}
        borderRadius="8px"
        border="1px solid"
        borderColor="neutralV2.5"
        padding="16px"
        gap="16px"
        //
      >
        <GoogleLoginBtn
          callbackUrl={callbackUrl ? decodeURIComponent(callbackUrl) : '/'}
        />

        <Divider
          sx={{
            flex: 1,
            borderColor: 'neutralV2.6',
            height: '2px',
          }}
        />

        <Stack
          component="form"
          gap="8px"
          onSubmit={handleSubmit(handleSendOtp)}
        >
          <AppTextField
            placeholder="email@endash.ai"
            name="email"
            control={control}
            error={errors['email'] != null}
            helperText={errors['email']?.message}
          />

          <AppButtonV2
            type="submit"
            variant="contain"
            color="tertiary"
            disabled={disabled}
            sx={{ padding: '9px', justifyContent: 'center' }}
          >
            <Typography variant="h5" color="inherit">
              {isSendingOtp ? tCommon('loading') : t('continue-with-email')}
            </Typography>
          </AppButtonV2>
        </Stack>
      </Stack>

      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="subhead5" color="neutralV2.25">
          {t('dont-have-account-yet')}
        </Typography>
        &nbsp;
        <Typography
          variant="h5"
          color="#2539BE"
          component={AppLink}
          href={getAuthUrlWithParams(AppRoutes.SIGNUP, email, callbackUrl)}
          sx={{ textDecoration: 'none' }}
        >
          {t('sign-up')}
        </Typography>
      </Box>
    </Stack>
  );
}
